import { t } from 'ttag';

export const REQUEST_MESSAGES = {
  /* fetch */

  fetch_error: () => t`Unable to fetch`,

  fetch_websites_error: () => t`Unable to fetch websites`,

  fetch_rentals_error: () => t`Unable to fetch rentals`,

  fetch_templates_error: () => t`Unable to fetch templates`,

  fetch_domains_error: () => t`Unable to fetch domains`,

  fetch_help_articles_error: () => t`Unable to fetch Help Articles`,

  fetch_changelogs_error: () => t`Unable to fetch Changelogs`,

  fetch_help_article_categories_error: () => t`Unable to fetch Categories`,

  fetch_customer_error: () => t`Unable to fetch Customer`,

  fetch_users_error: () => t`Unable to fetch Users`,

  fetch_integrations_error: () => t`Unable to fetch Integrations`,

  fetch_ideas_error: () => t`Unable to fetch ideas`,

  fetch_general_error: () => t`Error occurred while getting data`,

  fetch_dashboards_error: () => t`Unable to fetch dashboards`,

  fetch_report_error: () => t`Unable to fetch reports`,

  update_template_section_sort: () => t`Unable to order sections`,

  fetch_pro_templates_error: () => t`Unable to fetch Templates`,

  fetch_orgs_error: () => t`Unable to fetch organizations`,

  fetch_tags_error: () => t`Unable to fetch tags`,

  fetch_channels_error: () => t`Unable to fetch channels`,

  fetch_support_category_error: () => t`Unable to fetch Categories`,

  fetch_category_error: () => t`Unable to fetch categories`,

  fetch_canned_response_error: () => t`Unable to fetch canned responses`,

  /* mutation- website */

  create_website: () => ({
    success: t`Website created successfully`,
    error: t`Unable to create website`,
  }),

  clone_website: () => ({
    success: t`Website cloned successfully`,
    error: t`Unable to clone website`,
  }),

  update_website: () => ({
    success: t`Website updated successfully`,
    error: t`Unable to update website`,
  }),

  publish_website: () => ({
    success: t`Website published successfully`,
    error: t`Unable to publish website`,
  }),

  reset_website_settings: () => ({
    success: t`Website settings reset successfully`,
    error: t`Unable to reset website settings`,
  }),

  delete_website: () => ({
    success: t`Website deleted successfully`,
    error: t`Unable to delete website`,
  }),

  /* mutation - domain */

  create_domain_success: () => t`Domain added successfully`,
  create_domain_conflict: () => t`Domain is already occupied`,
  create_domain_error: () => t`Unable to add domain`,

  assign_domain: () => ({
    success: t`Domain assigned to the website successfully`,
    error: t`Unable to assign domain to the website`,
  }),

  unassign_domain: () => ({
    success: t`Domain unassigned successfully`,
    error: t`Unable to unassign domain`,
  }),

  connect_domain: () => ({
    success: t`Connect website to the domain successfully`,
    error: t`Unable to connect website to the domain`,
  }),

  delete_domain: () => ({
    success: t`Domain deleted successfully`,
    error: t`Unable to delete domain`,
  }),

  /* mutation - customer */
  create_customer: () => ({
    success: t`Create customer job has been queued`,
    error: t`Unable to create customer`,
  }),

  update_customer: () => ({
    success: t`Customer updated successfully`,
    error: t`Unable to update customer`,
  }),

  delete_customer: () => ({
    success: t`Account canceled successfully`,
    error: t`Unable to cancel account`,
  }),

  create_help_article: () => ({
    success: t`Help Article created successfully`,
    error: t`Unable to create Help Article`,
  }),

  update_help_article: () => ({
    success: t`Help Article updated successfully`,
    error: t`Unable to update Help Article`,
  }),

  delete_help_article: () => ({
    success: t`Help Article deleted successfully`,
    error: t`Unable to delete Help Article`,
  }),

  create_help_article_category: () => ({
    success: t`Category created successfully`,
    error: t`Unable to create Category`,
  }),

  update_help_article_category: () => ({
    success: t`Category updated successfully`,
    error: t`Unable to update Category`,
  }),

  delete_help_article_category: () => ({
    success: t`Category deleted successfully`,
    error: t`Unable to delete Category`,
  }),

  /* start mutation - user */
  create_user: () => ({
    success: t`User created successfully`,
    error: t`Unable to create user`,
  }),

  update_user: () => ({
    success: t`User updated successfully`,
    error: t`Unable to update user`,
  }),

  delete_user: () => ({
    success: t`User deleted successfully`,
    error: t`Unable to delete user`,
  }),
  /* end mutation - user */

  /* start mutation - integration */
  update_integration: () => ({
    success: t`Integration updated successfully`,
    error: t`Unable to update integration`,
  }),
  /* end mutation - integration */

  /* start mutation - Idea */
  create_idea: () => ({
    success: t`Idea created successfully`,
    error: t`Unable to create idea`,
  }),

  delete_idea: () => ({
    success: t`Idea deleted successfully`,
    error: t`Unable to delete idea`,
  }),

  create_idea_comment: () => ({
    success: t`Comment created successfully`,
    error: t`Unable to create comment`,
  }),

  update_idea_comment: () => ({
    success: t`Comment updated successfully`,
    error: t`Unable to update comment`,
  }),

  delete_idea_comment: () => ({
    success: t`Comment deleted successfully`,
    error: t`Unable to delete comment`,
  }),

  merge_idea: () => ({
    success: t`Idea merged successfully`,
    error: t`Unable to merge idea`,
  }),

  create_changelog: () => ({
    success: t`Changelog created successfully`,
    error: t`Unable to create Changelog`,
  }),

  update_changelog: () => ({
    success: t`Changelog updated successfully`,
    error: t`Unable to update Changelog`,
  }),

  delete_changelog: () => ({
    success: t`Changelog deleted successfully`,
    error: t`Unable to delete Changelog`,
  }),

  /* end mutation - Idea */

  /* mutation - Library Folder Item*/
  move_folder_error: () => t`Unable to move folder`,
  create_folder_item: () => ({
    success: t`Item created successfully`,
    error: t`Unable to create Item`,
  }),

  update_folder_item: () => ({
    success: t`Item updated successfully`,
    error: t`Unable to update Item`,
  }),

  delete_folder_item: () => ({
    success: t`Item deleted successfully`,
    error: t`Unable to delete Item`,
  }),

  duplicate_proposal_template_item: () => ({
    success: t`Item duplicated successfully`,
    error: t`Unable to duplicate item`,
  }),

  fetch_files_error: () => t`Unable to fetch files`,

  create_folder: () => ({
    success: t`Folder created successfully`,
    error: t`Unable to create folder`,
  }),

  rename_folder: () => ({
    success: t`Folder renamed successfully`,
    error: t`Unable to rename folder`,
  }),

  download_folder: () => ({
    success: t`Folder zip will be sent your email in 10 minutes`,
    error: t`Unable to download folder`,
  }),

  delete_folder: () => ({
    success: t`Folder deleted successfully`,
    error: t`Unable to delete folder`,
  }),

  create_dashboard: () => ({
    success: t`Dashboard created successfully`,
    error: t`Unable to create dashboard`,
  }),

  update_dashboard: () => ({
    success: t`Dashboard updated successfully`,
    error: t`Unable to update dashboard`,
  }),

  delete_dashboard: () => ({
    success: t`Dashboard deleted successfully`,
    error: t`Unable to delete dashboard`,
  }),

  create_report: () => ({
    success: t`Report created successfully`,
    error: t`Unable to create report`,
  }),
  update_report: () => ({
    success: t`Report updated successfully`,
    error: t`Unable to update report`,
  }),
  copy_report: () => ({
    success: t`Report duplicated successfully`,
    error: t`Unable to duplicate report`,
  }),
  delete_report: () => ({
    success: t`Report deleted successfully`,
    error: t`Unable to delete report`,
  }),

  fetch_proposal_template_error: () => t`Unable to fetch item`,
  fetch_fonts_error: () => t`Unable to fetch Google Fonts`,
  update_proposal_template_error: () => t`Unable to save template`,

  /* mutation - Store Folder Item*/

  /** Teams */
  fetch_teams_error: () => t`Unable to fetch teams`,
  create_team: () => ({
    success: t`Team created successfully`,
    error: t`Unable to create team`,
  }),

  update_team: () => ({
    success: t`Team updated successfully`,
    error: t`Unable to update team`,
  }),

  delete_team: () => ({
    success: t`Team deleted successfully`,
    error: t`Unable to delete team`,
  }),

  create_tag: () => ({
    success: t`Tag created successfully`,
    error: t`Unable to create tag`,
  }),

  update_tag: () => ({
    success: t`Tag updated successfully`,
    error: t`Unable to update tag`,
  }),

  delete_tag: () => ({
    success: t`Tag deleted successfully`,
    error: t`Unable to delete tag`,
  }),

  create_support_category: () => ({
    success: t`Category created successfully`,
    error: t`Unable to create category`,
  }),

  update_support_category: () => ({
    success: t`Category updated successfully`,
    error: t`Unable to update category`,
  }),

  delete_support_category: () => ({
    success: t`Category deleted successfully`,
    error: t`Unable to delete category`,
  }),

  /** Category */
  create_category: () => ({
    success: t`Category created successfully`,
    error: t`Unable to create category`,
  }),

  update_category: () => ({
    success: t`Category updated successfully`,
    error: t`Unable to update category`,
  }),
  sync_category: () => ({
    success: t`Categories synced successfully`,
    error: t`Unable to sync Categories`,
  }),

  delete_category: () => ({
    success: t`Category deleted successfully`,
    error: t`Unable to delete category`,
  }),

  create_canned: () => ({
    success: t`Canned response created successfully`,
    error: t`Unable to create response`,
  }),

  update_canned: () => ({
    success: t`Canned response updated successfully`,
    error: t`Unable to update response`,
  }),

  delete_canned: () => ({
    success: t`Canned response deleted successfully`,
    error: t`Unable to delete response`,
  }),
};
