import { CustomerAPI } from '@business/api/customer_api';
import { PageQuery } from '@business/entities/common';
import { CreateCustomerRequest, CustomerId, CustomerResponse, EditCustomerRequest } from '@business/entities/customer';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';
export class CustomerBloc extends BaseBloc {
  private readonly customerRepo = new Repository<CustomerResponse>({
    getItemId: item => item.id,
    paginated: true,
  });

  readonly items$ = this.customerRepo.items$.pipe(map(customers => R.sortBy(customers, c => c.name)));
  readonly selectItem = this.customerRepo.selectItem;

  onReset() {
    this.customerRepo.reset();
  }

  fetchCustomers = (query: PageQuery) => {
    return CustomerAPI.fetchCustomers(query);
  };

  fetchCustomer = (id: CustomerId) => {
    return CustomerAPI.fetchCustomer(id).pipe(
      this.customerRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_customer_error') }),
    );
  };

  createCustomer = (data: CreateCustomerRequest) => {
    return CustomerAPI.createCustomer(data).pipe(
      handleMessage({
        type: requestMessage('create_customer'),
      }),
    );
  };

  updateCustomer = (id: CustomerId, data: Partial<EditCustomerRequest>) => {
    return CustomerAPI.updateCustomer(id, data).pipe(
      this.customerRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_customer'),
      }),
    );
  };

  uploadLogo = (id: CustomerId, file: File) => {
    return CustomerAPI.updateLogo(id, file).pipe(
      this.customerRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_customer'),
      }),
    );
  };

  deleteCustomer = (id: CustomerId) => {
    return showDeleteConfirmation('Delete Account', 'Do you really want to cancel this account?', { btnLabel: 'Yes, Delete' }).pipe(
      switchMap(() => CustomerAPI.deleteCustomer(id)),
      this.customerRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_customer'),
      }),
    );
  };
}
