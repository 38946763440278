import { createToken } from '@core/utils/ioc';
import { CustomerBloc } from './customer_bloc';
import { HelpArticleBloc } from './help_article_bloc';
import { HelpArticleCategoryBloc } from './help_article_category_bloc';
import { IdeaCategoryBloc } from './idea_category_bloc';
import { IntegrationBloc } from './integration_bloc';
import { UserBloc } from './user_bloc';
import { IdeaBloc } from './idea_bloc';
import { ChangelogBloc } from './changelog_bloc/';
import { ServiceBloc } from './service_bloc';
import { ProposalTemplateBloc } from './proposal_template_bloc';
import { DashboardTemplateBloc } from './dashboard_template_bloc';
import { DynamincReportBloc } from './dynamic_report_bloc';
import { DashboardBloc } from './dashboard_bloc';
import { ReportBloc } from './report_bloc';
import { TeamBloc } from './team_bloc';
import { OrganizationBloc } from './organization_bloc';
import { TagBloc } from './tag_bloc';
import { SupportChannelBloc } from './support_channel_bloc';
import { SupportCategoriesBloc } from './support_categories_bloc';
import { ReportCategoryBloc } from './report_category_bloc';
import { CannedBloc } from './canned_bloc';

export const Blocs = {
  // websites: createToken<WebsitesBloc>(),
  // rentals: createToken<RentalsBloc>(),
  // domains: createToken<DomainsBloc>(),
  customers: createToken<CustomerBloc>(),
  helpArticle: createToken<HelpArticleBloc>(),
  helpArticleCategory: createToken<HelpArticleCategoryBloc>(),
  user: createToken<UserBloc>(),
  integration: createToken<IntegrationBloc>(),
  ideaCategory: createToken<IdeaCategoryBloc>(),
  idea: createToken<IdeaBloc>(),
  changelog: createToken<ChangelogBloc>(),
  // Dummy blocks
  service: createToken<ServiceBloc>(),
  proposalTemplate: createToken<ProposalTemplateBloc>(),
  dashboardTemplate: createToken<DashboardTemplateBloc>(),
  dynamicReport: createToken<DynamincReportBloc>(),
  dashboard: createToken<DashboardBloc>(),
  // report: createToken<ReportBloc>(),
  // team: createToken<TeamBloc>(),
  // organization: createToken<OrganizationBloc>(),
  // tag: createToken<TagBloc>(),
  // supportChannel: createToken<SupportChannelBloc>(),
  // supportCategory: createToken<SupportCategoriesBloc>(),
  reportCategory: createToken<ReportCategoryBloc>(),
  canned: createToken<CannedBloc>(),
};
